<template>
  <div>
    <a-table
      :columns="columns"
      :row-key="(record) => record.indexNumber"
      :data-source="infoList"
      :pagination="false"
      :loading="tableLoading"
      bordered
      size="middle"
    >
      <template slot="action" slot-scope="record">
        <a-button type="link" @click="change(record.id)"> 编辑 </a-button>
        <a-button type="link" @click="delItem(record.id)"> 删除 </a-button>
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    colSpan: 0,
    dataIndex: "indexNumber",
    align: "center",
    width: 100,
  },
  {
    colSpan: 0,
    dataIndex: "tdeMaintenance",
    width: 200,
    align: "center",
  },
  {
    colSpan: 0,
    // dataIndex: "number",
    align: "center",
    customRender: (text, record) => {
      return record.number + "（" + record.unit + ")";
    },
    width: 600,
  },
  {
    colSpan: 0,
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
import * as api from "@/api/threeProofing.js";
export default {
  name: "peopleProofing",
  props: ["infoList", "type"],
  components: {},
  data() {
    return {
      columns,
      dataSource: [],
      tableLoading: false,
    };
  },
  methods: {
    change(id) {
      this.$emit("showModal", id, this.type);
    },

    delItem(id) {
      let that = this;
      this.$confirm({
        title: "确认删除?",
        onOk() {
          return new Promise((resolve, reject) => {
            api
              .delThreeProofings({
                id: id,
              })
              .then((res) => {
                that.openNotification("success", res.data.msg);
                resolve();
                that.$emit("getInfo");
              })
              .catch((res) => {
                that.openNotification("error", res.data.msg);
                resolve();
              });
          });
        },
      });
    },
    openNotification(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
